import React, { useEffect, useRef } from "react";
import "./App.css";
import "amazon-connect-streams";
import initCoreConnect from "./ccpInitializers/initCoreConnect";
import appConfig from "./mockAppConfig.json";
import { eventController } from "./events/eventController";
import Acknowledge from "./components/Acknowledge";

const App = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const mounted = useRef<boolean>(false);
  const newWindow = useRef<Window | null>(window);

  window.addEventListener("message", ({ origin, data }) => {
    // { origin } seems cool, we can route event/message traffic based on source
    // seems like an earmark for keeping event processing organized?
    // each origin has it's own schema for the { data } message
    // if we can package those up in interface format we can help solidify the
    // contracts between iframes
    // console.info(origin, data);


    if (appConfig.ccpUrl.includes(origin) && !newWindow.current?.closed) {
      newWindow.current?.close()
    }

    if (typeof data === "string") {
      eventController[data]();
    }
  });


  window.addEventListener("beforeunload", () => {
    if (!newWindow.current?.closed) {
      newWindow.current?.close()
    }
  })


  useEffect(() => {
    if (!mounted.current) {
      initCoreConnect(containerRef.current, appConfig);
      connect.core.onIframeRetriesExhausted(() => {
        connect.core.terminate()
      })
      mounted.current = true;
    }
  }, []);


  newWindow.current = window.open(
    appConfig.loginUrl,
    "CCP",
    "width=600,height=400,left=200,top=200"
  );


  return (
    <div className="App">
      <Acknowledge />
      <div id="citrix-banner">
        <h3>You are running this site in CITRIX.</h3>
      </div>
      <div id="connect-container" ref={containerRef} />
      {/*<div id="crm-wrapper" style={{ width: "80%", height: "100vh" }}>*/}
      {/*  <iframe*/}
      {/*    style={{ height: "100%", width: "100%" }}*/}
      {/*    src="http://localhost:7000/main"*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  );
};

export default App;
