const initAgentSubscription = () => {
  connect.agent((agent: connect.Agent) => {
    const status = agent.getStatus();
    console.log("Agent subscription event:", status);
    agent.onStateChange((_agent) => {
      const { newState } = _agent;
      console.log("Agent subscription event 'onStateChange':", _agent);
      if (newState === "Meeting") {
        console.warn({ hullabaloo: newState });
        window.postMessage("agentInMeeting", "*");
      }
    });
    agent.onRoutable((_agent) => {
      console.log("Agent subscription event 'onRoutable':", _agent);
    });
    agent.onError((_agent) => {
      console.log("Agent subscription event 'onError':", _agent);
    });
    agent.onNotRoutable((_agent) => {
      console.log("Agent subscription event 'onNotRoutable':", _agent);
    });
  });
};

export default initAgentSubscription;
