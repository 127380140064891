import "amazon-connect-streams";
import { AppConfig } from "../types/";
import initAgentSubscription from "./initAgentSubscription";

const initCoreConnect = (
  container: HTMLDivElement | null,
  appConfig: AppConfig
) => {
  if (!appConfig) {
    throw new Error("Oops! No AppConfig found.");
  }
  try {
    if (container) {
      container && connect.core.initCCP(container, appConfig);
      initAgentSubscription();
    } else {
      throw new Error("Failed to initialize Amazon CCP in initCoreConnect");
    }
  } catch (e) {
    console.error(e);
  }
  return;
};

export default initCoreConnect;
